import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import THHockeyChallenge from 'th-hockey-challenge';
import { createBrowserHistory } from 'history';
/**
 * this implementation was used when we were building this component as a stand
 * alone component for CTG to consume.
 * this is now being implemented as an iframe - none of these parameters count anymore.
 */
const history = createBrowserHistory();
ReactDOM.render(
  <Router history={history}>
    <THHockeyChallenge
      config={{
        platform: 'web',
        apiUrl: 'https://u88low4ap4.execute-api.us-east-1.amazonaws.com/v0',
        env: 'staging',
        routeBase: '',
        language: 'en',
        userId: 'us-east-1:c61210c0-8b45-432c-90fb-c362f93c5483',
      }}
    />
  </Router>,
  document.getElementById('root'),
);
